import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [6,3];

export const dictionary = {
		"/": [~7],
		"/(public)/auth/email-sent": [17,[6]],
		"/(public)/auth/forgot": [18,[6]],
		"/(public)/auth/login": [19,[6]],
		"/(public)/auth/recovery": [20,[6]],
		"/(public)/auth/start": [21,[6]],
		"/(public)/auth/verify-email": [22,[6]],
		"/(private)/(cabinet)/cases": [10,[3,4]],
		"/(private)/(cabinet)/cases/create-success": [14,[3,4]],
		"/(private)/(cabinet)/cases/create": [12,[3,4]],
		"/(private)/(cabinet)/cases/create/lyft-redirect": [13,[3,4]],
		"/(private)/(cabinet)/cases/[caseId]": [11,[3,4]],
		"/(common)/info/privacy-policy": [8,[2]],
		"/(common)/info/terms-of-use": [9,[2]],
		"/(private)/onboarding": [16,[3,5]],
		"/(private)/(cabinet)/profile": [15,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';